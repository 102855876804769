import ReactPixel from 'react-facebook-pixel';

const FACEBOOK_PIXEL_ID = "368086393869391";

export const initFbPixel = (pixelId = FACEBOOK_PIXEL_ID,) => {
    ReactPixel.init(pixelId, {}, {
        autoConfig: true,
        debug: false,
    });
}

export const trackPageView = (pixelId = FACEBOOK_PIXEL_ID) => {
    try {
        ReactPixel.fbq('trackSingle', pixelId, 'PageView');
    } catch (error) {
        // TO-DO Bugsnag
        console.error(error);
    }
}

export const trackCustomEvent = (event, data, pixelId = FACEBOOK_PIXEL_ID) => {
    try {
        ReactPixel.trackSingleCustom(pixelId, event, data);
    } catch (error) {
        // TO-DO Bugsnag
        console.error(error);
    }
}
